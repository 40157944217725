import { useEffect, useState, useRef, useMemo } from 'react'
import {
	Stack,
	Grid,
	Box,
	Button,
	TextField,
	FormControl,
	Typography,
	LinearProgress,
	Select,
	SelectChangeEvent,
	useMediaQuery,
	MenuItem,
	Tooltip,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import InsertLinkIcon from '@mui/icons-material/InsertLink'
import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridToolbar,
	GridDensity,
	useGridApiRef,
	GridRowModel,
	GridColumnOrderChangeParams,
	GridColumnVisibilityModel,
	GridColumns,
	GridCellParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	GridComparatorFn,
	GridEventListener,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'

import {
	getGridColumnOrderSettingsV2,
	getGridColumnVisibleSettingsV2,
	getSettings,
	setGridColumnOrderSettingsV2,
	setGridColumnVisibleSettingsV2,
} from 'helpers/storage'

import { selectuserInfo } from 'hooks/userInfo'
import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { useDispatch, useSelector } from 'react-redux'

import { extractCmmnCode } from 'util/cmmnCodeUtil'
import ExportToXLSXImg from 'assets/img/ExportToXLSX_32x32.png'
import PrintImg from 'assets/img/Print_32x32.png'
import PrintAreaImg from 'assets/img/PrintArea_32x32.png'
import ResultCompareImg from 'assets/img/ResultCompare_32x32.png'
import SelecedtPrintImg from 'assets/img/SelectedPrint_32x32.png'
import PuzzleImg from 'assets/img/free-icon-doc-file-9664098.png'
import SlSearchImg from 'assets/img/free-icon-link-2885430.png'

import moment from 'moment'
import 'moment/locale/ko'

import axios from 'axios'

import SearchIcon from '@mui/icons-material/Search'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TodayIcon from '@mui/icons-material/Today'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import registMouseDownDrag from 'components/registMouseDownDrag'
import {
	alertModal,
	compareDate,
	confirmAlert,
	isValidDateType,
	timerAlert,
} from 'util/util'
import {
	receptionDetailV2,
	receptionListV2,
	receptionView,
	apiReceptionSelectByPatientInfoV2,
	apiReceptionItemSelectByPatientInfoV2,
	apiSBReceptionList,
	apiSBReserve,
	apiSBList,
	prevReceptionChartData,
} from 'api/api'
import { valuesInIterator } from 'util/objectUtil'
import { InspectionResultDetailType } from 'constants/types'
import ClientSearchPopup from './clientSearchPopup'
import { replaceBrTag } from 'util/stringUtil'
import { setGridColumnV2 } from 'hooks/gridColumnOrderV2'
import { setGridColumnVisibleV2 } from 'hooks/gridColumnVisibleV2'

import ResultV2Popup from './ResultV2Popup'
import { useNavigate } from 'react-router-dom'
import SlideBlockPopup from 'components/SlideBlockPopup'
import PrevResult from './PrevResult'
import ArrowUp from '../../assets/img/ArrowUp.svg'
import ArrowDown from '../../assets/img/ArrowDown.svg'
import ArrowBoth from '../../assets/img/ArrowBoth.svg'

let searchClientID = ''

const stateCategoryCompare: GridComparatorFn<any> = (v1: any, v2: any) => {
	if (v1 === '완료' && v2 === '완료') return 0
	if (v1 === '완료' && v2 !== '완료') return 1
	if (v1 !== '완료' && v2 === '완료') return -1
	return 0
}

const columns: GridColDef[] = [
	{
		field: 'ReceptionID',
		headerName: '접수ID',
		type: 'string',
		width: 80,
		editable: false,
		hide: true,
	},
	{
		field: 'rowNum',
		headerName: '순번',
		type: 'string',
		width: 50,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.rowNum}
				</span>
			)
		},
	},
	{
		field: 'StateCategory',
		headerName: '상태',
		type: 'string',
		width: 85,
		editable: false,
		// sortComparator: stateCategoryCompare,
		// sortingOrder: ['desc', 'asc', null],
		renderCell: (params) => {
			if (params?.row?.StateCategory === '접수') {
				return (
					<span
						style={{
							background: '#8d8d8d',
							padding: '1px 3px',
							border: '1px solid #8d8d8d',
							color: '#fff',
							width: '100%',
							display: 'block',
							textAlign: 'center',
						}}
					>
						접수
					</span>
				)
			} else if (params?.row?.StateCategory === '재검') {
				return (
					<span
						style={{
							background: '#e67e22',
							padding: '1px 3px',
							border: '1px solid #e67e22',
							color: '#fff',
							width: '100%',
							display: 'block',
							textAlign: 'center',
						}}
					>
						재검
					</span>
				)
			} else if (
				params?.row?.StateCategory === '최종보고' &&
				params?.row?.IsOver
			) {
				if (params?.row?.isPrint) {
					return (
						<span
							style={{
								color: '#fff',
								background: '#000066',
								padding: '1px 3px',
								border: '1px solid #000066',
								width: '100%',
								display: 'block',
								textAlign: 'center',
							}}
						>
							출력완료
						</span>
					)
				} else {
					return (
						<span
							style={{
								color: '#fff',
								background: '#3333FF',
								padding: '1px 3px',
								border: '1px solid #3333FF',
								width: '100%',
								display: 'block',
								textAlign: 'center',
							}}
						>
							최종보고
						</span>
					)
				}
			} else if (
				params?.row?.StateCategory === '최종보고' &&
				!params?.row?.IsOver
			) {
				return (
					<span
						style={{
							color: '#000',
							background: '#99d696',
							padding: '1px 3px',
							border: '1px solid #99d696',
							width: '100%',
							display: 'block',
							textAlign: 'center',
						}}
					>
						검사진행
					</span>
				)
			} else if (
				params?.row?.StateCategory === '검사진행' ||
				params?.row?.StateCategory === '진행'
			) {
				return (
					<span
						style={{
							color: '#000',
							background: '#99d696',
							padding: '1px 3px',
							border: '1px solid #99d696',
							width: '100%',
							display: 'block',
							textAlign: 'center',
						}}
					>
						검사진행
					</span>
				)
			}
		},
	},
	{
		field: 'ReceptionDate',
		headerName: '접수일',
		type: 'string',
		width: 100,
		editable: false,
		renderCell: (params) => {
			const { isRed, StateCategory } = params?.row
			return (
				<span
					style={{
						color: StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ReceptionDate}
				</span>
			)
		},
	},
	{
		field: 'PatientName',
		headerName: '수진자',
		type: 'string',
		width: 70,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.PatientName}
				</span>
			)
		},
	},
	{
		field: 'DepartName',
		headerName: '진료과',
		type: 'string',
		width: 70,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.DepartName}
				</span>
			)
		},
	},
	{
		field: 'DoctorName',
		headerName: '의사명',
		type: 'string',
		width: 70,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.DoctorName}
				</span>
			)
		},
	},
	{
		field: 'ChartNo',
		headerName: '차트번호',
		type: 'number',
		width: 80,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ChartNo}
				</span>
			)
		},
	},
	{
		field: 'BirthDay',
		headerName: '생년월일',
		type: 'string',
		width: 100,
		hide: true,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.PartCode}
				</span>
			)
		},
	},
	// {
	// 	field: 'PartName',
	// 	headerName: '보고서',
	// 	type: 'string',
	// 	width: 100,
	// },
	{
		field: 'PartCode',
		headerName: 'PartCode',
		type: 'string',
		width: 50,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.PartCode}
				</span>
			)
		},
	},
	{
		field: 'ReceptionRegNum',
		headerName: '접수번호',
		type: 'string',
		width: 75,
		editable: false,
		renderCell: (params) => {
			const { isRed } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{params?.row?.ReceptionRegNum}
				</span>
			)
		},
	},
	{
		field: 'ClientInfo',
		headerName: 'S/A',
		type: 'string',
		width: 45,
		editable: false,
		renderCell: (params) => {
			const { isRed, ClientInfo } = params?.row
			return (
				<span
					style={{
						color:
							params?.row?.StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{ClientInfo}
				</span>
			)
		},
	},
	{
		field: 'Decision',
		headerName: '판정',
		type: 'string',
		width: 50,
		editable: false,
		renderCell: (params) => {
			if (params?.row?.IsOver) {
				if (
					params?.row?.StateCategory === '최종보고' &&
					params?.row?.Decision === 'L'
				) {
					return (
						<img
							src={ArrowDown}
							alt="L"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else if (
					params?.row?.StateCategory === '최종보고' &&
					params?.row?.Decision === 'H'
				) {
					return (
						<img
							src={ArrowUp}
							alt="H"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else if (
					params?.row?.StateCategory === '최종보고' &&
					params?.row?.Decision === 'A'
				) {
					return (
						<img
							src={ArrowBoth}
							alt="A"
							style={{ width: '8px', display: 'block', margin: '0 auto' }}
						/>
					)
				} else {
					return <></>
				}
			} else {
				return <></>
			}
		},
	},
	{
		field: 'PartName',
		headerName: '학부',
		type: 'string',
		width: 80,
		editable: false,
		align: 'center',
		hideable: true,
		renderCell: (params) => {
			const { PartName, StateCategory, isRed } = params?.row
			return (
				<span
					style={{
						color: StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
					}}
				>
					{PartName === 'STD/HPV' || PartName === '미생물학' ? '진단' : PartName}
				</span>
			)
		},
	},
	{
		field: 'isView',
		headerName: '읽음',
		type: 'string',
		width: 40,
		editable: false,
		align: 'center',
		renderCell: (params) => {
			const { IsOver, isRed, StateCategory } = params?.row
			if (params?.row?.isView === 1 && IsOver) {
				return (
					<span
						style={{
							color: StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
						}}
					>
						Y
					</span>
				)
			} else {
				return <></>
			}
		},
	},
	{
		field: 'isPrint',
		headerName: '출력여부',
		type: 'string',
		width: 75,
		editable: false,
		align: 'center',
		hideable: true,
		renderCell: (params) => {
			const { isPrint, StateCategory, isRed } = params?.row
			if (isPrint === 1) {
				return (
					<span
						style={{
							color: StateCategory === '최종보고' && isRed ? 'red' : 'inherit',
						}}
					>
						Y
					</span>
				)
			} else {
				return <></>
			}
		},
	},
]

const locales = ['ko'] as const

const SearchContainer = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: 'auto auto',
	gridTemplateColumns: 'auto auto',
}))

const DatePickerWrapper = styled('div')(({ theme }) => ({
	'& .MuiFormControl-root': {
		background: '#fff',
	},
}))

const InputWrapper = styled('div')(({ theme }) => ({
	'& .MuiInputBase-root': {
		background: '#fff',
	},
}))

const FormButtons = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	paddingLeft: '10px',
}))

const Btn = styled('button')(({ theme }) => ({
	color: '#fff',
	background: 'transparent',
	border: 0,
	outline: 0,
	marginRight: '5px',
	cursor: 'pointer',
	'&:last-child': {
		marginRight: 0,
	},
}))

const PageBg = styled('div')(({ theme }) => ({
	padding: '1rem',
	borderRadius: '0.5rem',
	background: theme.palette.custom.wrapper,
	filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	marginTop: '1rem',
}))

const DragWrapper = styled('div')(({ theme }) => ({
	position: 'relative',
	width: '100%',
	height: 'calc(100vh - 230px)',
}))
const PatientInfoContainer = styled('div')(({ theme }) => ({}))

const Item = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#AACDE8' : '#AACDE8',
	...theme.typography.body2,
	padding: '0.1rem 0.3rem',
	textAlign: 'center',
	color: theme.palette.mode === 'dark' ? '#000000' : '#000000',
	border:
		theme.palette.mode === 'dark'
			? '1px solid rgba(224, 224, 224, 0.5)'
			: '1px solid rgba(224, 224, 224, 0.5)',
}))

const HeaderTableContaienr = styled('div')(({ theme }) => ({
	overflow: 'auto',
}))
const HeaderTable = styled('table')(({ theme }) => ({
	tableLayout: 'fixed',
	width: '100%',
	borderCollapse: 'collapse',
	'th, td': {
		padding: '0.2rem 0.3rem',
		border:
			theme.palette.mode === 'dark'
				? '1px solid rgba(224, 224, 224, 0.5)'
				: '1px solid rgba(224, 224, 224, 0.5)',
	},
	th: {
		backgroundColor: theme.palette.mode === 'dark' ? '#AACDE8' : '#AACDE8',
		color: theme.palette.mode === 'dark' ? '#000' : '#000',
	},
	td: { backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF' },
	// th: { backgroundColor: theme.palette.mode === 'dark' ? '#835FED' : '#835FED' },
	// td: { backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF' },
	'@media (max-width: 767px)': {
		tableLayout: 'auto',
	},
}))

const ReportButtonContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	' @media (max-width: 767px)': {
		display: 'block',
		marginTop: '10px',
		button: {
			marginBottom: '10px',
		},
	},
}))

const ResultV2 = () => {
	const dispatch = useDispatch()
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
	const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

	const [selectedRowPartName, setSelectedRowPartName] = useState<string>('')
	const [selectedGrid1Row, setSelectedGrid1Row] = useState<any>(null)
	const [isContainComment, setIsContainComment] = useState<boolean>(false)

	const columnsSubP0P1: GridColDef[] = useMemo(
		() => [
			{
				field: 'ReceptionID',
				headerName: '접수ID',
				type: 'string',
				width: 80,
				editable: false,
				hide: true,
			},
			{
				field: 'rowNum',
				headerName: '순번',
				type: 'string',
				width: 50,
				editable: false,
				renderCell: (params) => {
					const { isRed } = params?.row
					return (
						<span
							style={{
								color:
									params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
							}}
						>
							{params?.row?.rowNum}
						</span>
					)
				},
			},
			{
				field: 'InsuranceCode',
				headerName: '보험코드',
				width: 100,
			},
			{
				field: 'TestName',
				headerName: '검사항목',
				width: 300,
				renderCell: (params) => {
					const { Decision } = params.row
					return Decision ? <b>{params.value}</b> : params.value
				},
			},
			{
				field: 'ResultText2Short',
				headerName: '소견결과',
				width: 400,
				renderCell: (params) => {
					const { ResultText2Short, PathologistShortInfo } = params.row
					const result =
						(ResultText2Short ? ResultText2Short : '') +
						(PathologistShortInfo ? PathologistShortInfo : '')
					return (
						<p
							dangerouslySetInnerHTML={{
								__html: replaceBrTag(result),
							}}
						></p>
					)
				},
			},
			{
				field: 'SpecimenName',
				headerName: '검체명',
				width: 150,
			},
			{
				field: 'PathologistShortInfo',
				headerName: '판독의',
				width: 170,
				renderCell: (params) => {
					return (
						<p dangerouslySetInnerHTML={{ __html: replaceBrTag(params?.value) }}></p>
					)
				},
			},
		],
		[]
	)

	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({
			rowNum: false,
			ReceptionID: false,
			ResultText: isContainComment,
			BirthDay: false,
			DoctorName: false,
			PartCode: false,
		})

	const columnsSub: GridColDef[] = useMemo(
		() => [
			{
				field: 'ReceptionID',
				headerName: '접수ID',
				type: 'string',
				width: 80,
				editable: false,
				hide: true,
			},
			{
				field: 'rowNum',
				headerName: '순번',
				type: 'string',
				width: 50,
				hide: true,
				editable: false,
				renderCell: (params) => {
					const { isRed } = params?.row
					return (
						<span
							style={{
								color:
									params?.row?.StateCategory === '완료' && isRed ? 'red' : 'inherit',
							}}
						>
							{params?.row?.rowNum}
						</span>
					)
				},
			},
			{
				field: 'InsuranceCode',
				headerName: '보험코드',
				width: 100,
			},
			{
				field: 'TestName',
				headerName: '검사항목',
				width: 180,
				renderCell: (params) => {
					const { Decision } = params.row
					return Decision ? <b>{params.value}</b> : params.value
				},
			},
			{
				field: 'ResultData',
				headerName: '검사결과1',
				width: 150,
				renderCell: (params) => {
					const { Decision, LastStateCode, ResultPlanDate } = params.row
					if (LastStateCode === 'D90' || LastStateCode === 'D50') {
						return (
							<div>
								<b
									dangerouslySetInnerHTML={{
										__html: replaceBrTag(params.value),
									}}
								></b>
							</div>
						)
					} else {
						return (
							<Typography
								variant="body2"
								sx={{ color: theme.palette.warning.main, textAlign: 'center' }}
							>
								{ResultPlanDate}
							</Typography>
						)
					}
				},
			},
			{
				field: 'ResultData2',
				headerName: '검사결과2',
				width: 150,
				hide: true,
				renderCell: (params) => {
					return (
						<div
							dangerouslySetInnerHTML={{
								__html: replaceBrTag(params.value),
							}}
						></div>
					)
				},
			},
			{
				field: 'ResultText',
				headerName: '소견결과',
				width: 250,
				hide: !isContainComment,
				renderCell: (params) => {
					return (
						<div
							dangerouslySetInnerHTML={{
								__html: replaceBrTag(params.value),
							}}
						></div>
					)
				},
			},
			{
				field: 'Decision',
				headerName: '판정',
				align: 'center',
				width: 80,
				renderCell: (params) => {
					const Decision: string = params.row.Decision as string
					// decision remove empty space
					const rederText = (decision: string) => {
						decision = decision !== undefined ? decision.replace(/\s/g, '') : ''

						if (decision !== undefined && decision === 'L') {
							return '#0288d1' // blue
						} else {
							return '#FF5722' // red
						}
					}
					return (
						<Typography
							variant="body2"
							sx={{
								color: rederText(Decision),
							}}
						>
							<b>{Decision}</b>
						</Typography>
					)
				},
			},
			{
				field: 'PrvResultData',
				headerName: '이전결과',
				width: 150,
				renderCell: (params) => {
					const { PatientName, SocialNumber, TestCode } = params.row
					return (
						<button
							type="button"
							onClick={() => getPrevResult(PatientName, SocialNumber, TestCode)}
							style={{
								border: 0,
								outline: 0,
								background: 'transparent',
								cursor: 'pointer',
								textDecoration: 'underline',
								color: '#0000FF',
							}}
						>
							{params.value}
						</button>
					)
				},
			},
			{
				field: 'Remark',
				headerName: '주석',
				width: 150,
				renderCell: (params) => {
					return (
						<div
							dangerouslySetInnerHTML={{
								__html: replaceBrTag(params.value),
							}}
						></div>
					)
				},
			},
			{
				field: 'Reference',
				headerName: '참고치',
				width: 150,
				renderCell: (params) => {
					return (
						<div
							dangerouslySetInnerHTML={{
								__html: replaceBrTag(params.value),
							}}
						></div>
					)
				},
			},
			{
				field: 'Unit',
				headerName: '단위',
				width: 100,
			},
			{
				field: 'SpecimenName',
				headerName: '검체명',
				width: 150,
			},
		],
		[isContainComment]
	)

	const gridColumnOrder = getGridColumnOrderSettingsV2()
	const gridColumnVisible = getGridColumnVisibleSettingsV2()

	const defaultLeftColumnOrder = [
		'__check__',
		'rowNum',
		'ReceptionID',
		'StateCategory',
		'ReceptionDate',
		'PatientName',
		'ChartNo',
		'BirthDate',
		'Report',
	]
	// ResultText2Short SpecimenName PathologistShortInfo
	const defaultRightColumnOrder = [
		'__check__',
		'rowNum',
		'ReceptionID',
		'InsuranceCode',
		'TestName',
		'ResultData',
		'Decision',
		'PrvResultData',
		'Reference',
		'Unit',
		'ResultText',
		'Remark',
		'SpecimenName',
		'ResultData2',
	]
	const defaultRightP0P1ColumnOrder = [
		'__check__',
		'rowNum',
		'ReceptionID',
		'InsuranceCode',
		'TestName',
		'ResultText2Short',
		'SpecimenName',
		'PathologistShortInfo',
	]

	const [columnLeftOrder, setColumnLeftOrder] = useState<any[]>(
		gridColumnOrder?.left
			? [...gridColumnOrder?.left]
			: [...defaultLeftColumnOrder]
	)
	const [columnRightOrder, setColumnRightOrder] = useState<any[]>(
		gridColumnOrder?.right
			? [...gridColumnOrder?.right]
			: [...defaultRightColumnOrder]
	)
	// const [columnRightOrder, setColumnRightOrder] = useState<any[]>([
	// 	...defaultRightColumnOrder,
	// ])
	const [columnRightP0P1Order, setColumnRightP0P1Order] = useState<any[]>(
		gridColumnOrder?.rightP0P1
			? [...gridColumnOrder?.rightP0P1]
			: [...defaultRightP0P1ColumnOrder]
	)

	const [gridColumnLeftVisible, setGridColumnLeftVisible] = useState(
		gridColumnVisible?.left ? gridColumnVisible?.left : true
	)
	const [gridColumnRightVisible, setGridColumnRightVisible] = useState(
		gridColumnVisible?.right ? gridColumnVisible?.right : true
	)

	// 컬럼 정렬시 useMemo를 사용한다. 사용하지 않으면 재정렬이 안됨... 왜냐하면 컬럼에 함수를 호출하는 부분이 있는데 해당 부분때문에 외부로 빼질 못해서 요렇게 처리...
	const columnsRightMemo = useMemo(() => {
		return columnRightOrder
			.map((colId) => columnsSub.find((col) => col.field === colId))
			.filter(Boolean) as GridColDef[]
	}, [columnRightOrder, columnsSub])

	const columnsRightP0P1Memo = useMemo(() => {
		return columnRightP0P1Order
			.map((colId) => columnsSubP0P1.find((col) => col.field === colId))
			.filter(Boolean) as GridColDef[]
	}, [columnRightP0P1Order, columnsSubP0P1])

	const handleColumnReorder = (
		target: string,
		params: GridColumnOrderChangeParams
	) => {
		const { field, targetIndex } = params

		// Move field to the target index
		if (target === 'left') {
			console.log('left')
			const newColumnOrder = [...columnLeftOrder]
			const targetColumnIndex = newColumnOrder.findIndex((col) => col === field)
			newColumnOrder.splice(targetColumnIndex, 1)
			newColumnOrder.splice(targetIndex, 0, field)

			console.table(newColumnOrder)

			// Update the state using the previous state
			const order = {
				left: [...newColumnOrder],
				right: [...columnRightOrder],
			}
			setColumnLeftOrder(newColumnOrder)

			setGridColumnOrderSettingsV2(order)
			dispatch(setGridColumnV2(order))
		} else if (target === 'right') {
			console.log('right')
			const newColumnOrder = [...columnRightOrder]
			const targetColumnIndex = newColumnOrder.findIndex((col) => col === field)
			newColumnOrder.splice(targetColumnIndex, 1)
			newColumnOrder.splice(targetIndex, 0, field)

			console.table(newColumnOrder)

			// Update the state using the previous state
			const order = {
				left: [...columnLeftOrder],
				right: [...newColumnOrder],
			}
			setColumnRightOrder(newColumnOrder)

			setGridColumnOrderSettingsV2(order)
			dispatch(setGridColumnV2(order))
		}
	}

	const handleColumnVisibleChange = (
		target: string,
		params: GridColumnVisibilityModel
	) => {
		const data = { ...params }
		console.log(data)

		if (target === 'left') {
			setGridColumnLeftVisible(data)
			const order = {
				left: { ...data },
				right: { ...gridColumnRightVisible },
			}
			setGridColumnVisibleSettingsV2(order)
			dispatch(setGridColumnVisibleV2(order))
		} else if (target === 'right') {
			setGridColumnRightVisible(data)
			const order = {
				left: { ...gridColumnLeftVisible },
				right: { ...data },
			}
			setGridColumnVisibleSettingsV2(order)
			dispatch(setGridColumnVisibleV2(order))
		}
	}

	const env = getSettings()
	const userInfo = useSelector(selectuserInfo)
	const settings = getSettings()

	const navigate = useNavigate()

	const [locale] = useState<(typeof locales)[number]>('ko')

	const [rptTypeCodeList, setRptTypeCodeList] = useState<any>([])

	// ui
	const cmmnCdList = useSelector(selectCmmnCode)
	const [isSearchLoading, setIsSearchLoading] = useState(false)
	const [reportUrl, setReportUrl] = useState('')
	const [imageList, setImageList] = useState<any>([])
	const [fileList, setFileList] = useState<any>([])
	const [rptRowClicked, setRptRowClicked] = useState(false)

	const grid1Ref = useGridApiRef()

	const grid2Ref = useGridApiRef()

	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	// api response
	const [receptionListData, setReceptionListData] = useState<any[]>([])
	const [size1, setSize1] = useState(env.row ? env.row : 100)
	const [size2, setSize2] = useState(env.row ? env.row : 100)
	const [grid1isLoading, setGrid1isLoading] = useState(false)
	const [grid2isLoading, setGrid2isLoading] = useState(false)
	const [reportLoading, setReportLoading] = useState(false)
	const [disableRowClick, setDisableRowClick] = useState(false)
	const [page1, setPage1] = useState(0)
	const [page2, setPage2] = useState(0)

	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [subSelectionModel, setSubSelectionModel] = useState<GridSelectionModel>(
		[]
	)

	// 첨부파일에 pdf가 있을 경우의 변수값
	const [pdfActive, setPdfActive] = useState(false)
	const [pdfBlob, setPdfBlob] = useState<any>(null)

	const [disabledReport, setDisabledReport] = useState(true)
	const [disabledImage, setDisabledImage] = useState(true)
	const [disabledExcel, setDisabledExcel] = useState(true)
	const [receptioDetailHeader, setReceptionDetailHeader] = useState({
		ChartNo: '',
		ClientInfo: '',
		CollectedDate: '',
		DepartInfo: '',
		DepartName: '',
		ClientName: '',
		PatientName: '',
		ReceptionDate: '',
		ReceptionID: 0,
		ReceptionInfo: '',
		ReceptionInfoDetail: '',
		ReceptionRegNum: '',
		SpecimenName: '',
		LastStateDate: '',
		RptTypeCode: '',
		TestCode: '',
		LabCode: '',
		LicenseDoctor: '',
		ReceptionUserInfo: '',
		Ward: '',
		ReportMemo: '',
		RItemID: '',
		SocialNumber: '',
		DoctorName: '',
		TotalUrineVolume: '',
		BirthDay: '',
		PathologyNo: '',
		PatientAge: '',
	})
	const [receptionDetailList, setReceptionDetailList] = useState([])
	const [rptTypeList, setRptTypeList] = useState([])

	const [isDateRangeChanged, setIsDateRangeChanged] = useState(false)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [search, setSearch] = useState({
		clientID: userInfo?.infoType === 'C' ? userInfo?.clientID : '',
		patientName: '',
		chartNo: '',
		receptionRegNum: '',
		lastStateCode: '0',
		searchTestCd: userInfo?.testCd || '0',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		departName: userInfo.departName || '',
		ward: userInfo.ward || '',
		doctorName: '',
		isPDC: false,
		searchType: 'R',
		socailNumber: '',
		testName: '',
		ignoreDate: '0',
		insuranceCode: '',
		isSearchAll: true,
		searchKeyword: '',
	})

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		setIsDateRangeChanged(false)
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setSearch({
				...search,
				[target]: moment(new Date(e)).format('yyyy-MM-DD'),
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, search.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, search.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setSearch((prevState) => {
					return {
						...search,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const actionSearch = () => {
		if (!disabledSearch) getReceptionList()
	}

	const getReceptionList = () => {
		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			search.clientID === ''
		) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		if (search.socailNumber) {
			if (search.socailNumber.length !== 8) {
				alertModal('주민번호는 뒤 1자리까지 입력하세요.', 'error', () => {})
				return
			} else {
				const regexp = /^\d{6}-[1-8]$/g
				if (!regexp.test(search.socailNumber)) {
					alertModal('주민번호 형식이 올바르지 않습니다.', 'error', () => {})
					return
				}
			}
		}
		const startDate = moment(search.startDate)
		const endDate = moment(search.endDate)

		// const monthDiff = endDate.diff(startDate, 'months')
		const dayDiff = endDate.diff(startDate, 'days')

		// 검색기간 6개월 초과 시 testSearch.patientName 또는 testSearch.socialNumber 또는 testSearch.chartNo 중에 하나는 입력되어야 함
		if (
			dayDiff > 365 &&
			!isDateRangeChanged &&
			!search.patientName &&
			!search.socailNumber &&
			!search.chartNo
		) {
			alertModal(
				'검색기간이 <span style="color: red;font-weight: bold;">12개월</span>을 초과할 경우<br/><span style="color: red;font-weight: bold;">조회종류(수진자명, 차트번호, 주민번호)</span> 를<br/>변경하시고 조회부탁 드립니다.',
				'info',
				() => {}
			)
			return
		}
		setIsSearchLoading(true)
		setSelectedGrid1Row(null)
		setSlideBlockBtnActive(false)

		const request = {
			clientID: search.clientID as any,
			patientName: encodeURIComponent(search.patientName),
			chartNo: search.chartNo,
			receptionRegNum: search.receptionRegNum,
			lastStateCode: search.lastStateCode,
			searchTestCd: search.searchTestCd === '0' ? '' : search.searchTestCd,
			startDate: moment(search.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: search.startDate,
			endDate: search.endDate,
			departName: encodeURIComponent(search.departName),
			doctorName: encodeURIComponent(search.doctorName),
			isPDC: search.isPDC ? '1' : '',
			searchType: search.searchType,
			socailNumber: search.socailNumber,
			ward: search.ward,
			testName: search.testName,
			ignoreDate: search.ignoreDate,
			insuranceCode: search.insuranceCode,
			isSearchAll: search.isSearchAll ? '1' : '0',
			searchKeyword: encodeURIComponent(search.searchKeyword),
		}

		console.table(request)

		setRptTypeCodeList([])
		setReportUrl('')
		setPdfBlob(null)
		setPdfActive(false)
		setFileList([])
		setImageList([])
		setReceptionDetailList([])

		setRptRowClicked(false)

		setGrid1isLoading(true)

		setReportLoading(true)
		receptionListV2(request)
			.then((res) => {
				console.log(res)

				setReceptionListData(res)
				setReportUrl('')
				setDisableRowClick(false)
				setSelectionModel([])
			})
			.finally(() => {
				setReportLoading(false)
				setGrid1isLoading(false)
				setIsSearchLoading(false)
				setPage1(0)
				// 검색 실행 후 상단으로 스크롤
				const div = document.querySelector('.MuiDataGrid-virtualScroller')
				if (div) div.scrollTop = 0
				// window.scrollTo({ top: 0, behavior: 'smooth' })
			})
	}

	const [excelDownload, setExcelDownload] = useState(false)
	const excelDownloadRef = useGridApiRef()
	const [excelDataGridRows, setExcelDataGridRows] = useState<any>([])

	const actionExcel = () => {
		console.log('actionExcel')
		const selectedRows = grid1Ref.current?.getSelectedRows()
		// extract ReceptionID in selectedRows
		const values = valuesInIterator(selectedRows)
		const receptionIDs = values.map((row: any) => row.ReceptionID)
		setDisabledExcel(true)
		if (receptionIDs.length > 500) {
			confirmAlert(
				'500건 이상일 경우 시간이 소요될 수 있습니다.<br/>계속하시겠습니까?',
				() => {
					setExcelDownload(true)
					// receptionIDs to string
					const receptionIDsString = receptionIDs.join(',')

					// get html title
					const title = document.title

					const TmpTitle =
						userInfo.infoType === 'C'
							? moment().format('YYYYMMDD') + '_' + userInfo.clientName
							: moment().format('YYYYMMDD') + '_' + searchClientName

					setExcelDataGridRows([])

					// html title to TmpTitle
					document.title = TmpTitle

					axios
						.post(`${process.env.REACT_APP_HOST}/report/download/excelV2`, {
							receptionIDs: receptionIDsString,
							clientID:
								userInfo.infoType === 'S' || userInfo.infoType === 'W'
									? search.clientID
									: userInfo.clientID,
						})
						.then((res) => {
							console.log(res.data)
							res.data.forEach((element: any) => {
								const { ReceptionDate } = element
								element.ReceptionDate2 = ReceptionDate
								if (element?.SocialNumber && element?.SocialNumber.length === 8) {
									element.SocialNumber = element.SocialNumber.substring(0, 6)
									if (element?.PatientAge) {
										element.SocialNumber += ` (${element.PatientAge})`
									}
								}
							})
							setExcelDataGridRows(res.data)
							setTimeout(() => {
								excelDownloadRef.current?.exportDataAsExcel()
								setTimeout(() => {
									document.title = title
								}, 500)
							}, 500)
						})
						.finally(() => {
							setExcelDownload(false)
							setDisabledExcel(false)
						})
				},
				() => {}
			)
			return
		} else {
			setExcelDownload(true)
			// receptionIDs to string
			const receptionIDsString = receptionIDs.join(',')

			console.log(receptionIDsString)

			// get html title
			const title = document.title

			const TmpTitle =
				userInfo.infoType === 'C'
					? moment().format('YYYYMMDD') + '_' + userInfo.clientName
					: moment().format('YYYYMMDD') + '_' + searchClientName

			setExcelDataGridRows([])

			// html title to TmpTitle
			document.title = TmpTitle

			axios
				.post(`${process.env.REACT_APP_HOST}/report/download/excelV2`, {
					receptionIDs: receptionIDsString,
					clientID:
						userInfo.infoType === 'S' || userInfo.infoType === 'W'
							? search.clientID
							: userInfo.clientID,
				})
				.then((res) => {
					console.log(res.data)
					res.data.forEach((element: any) => {
						const { ReceptionDate } = element
						element.ReceptionDate2 = ReceptionDate
						if (element?.SocialNumber && element?.SocialNumber.length === 8) {
							element.SocialNumber = element.SocialNumber.substring(0, 6)
							if (element?.PatientAge) {
								console.log(element?.PatientAge)
								element.SocialNumber += ` (${element.PatientAge})`
							}
							console.log(element.SocialNumber)
						}
					})
					setExcelDataGridRows(res.data)
					setTimeout(() => {
						excelDownloadRef.current?.exportDataAsExcel()
						setTimeout(() => {
							document.title = title
						}, 500)
					}, 500)
				})
				.finally(() => {
					setExcelDownload(false)
					setDisabledExcel(false)
				})
		}
	}

	// 수진자 상세 정보
	const getReceptionDetail = async (receptionID: number, RItemID: number) => {
		if (receptionID) {
			setDisabledReport(false)
			setDisabledImage(false)
			setDisabledExcel(false)
		} else {
			setDisabledReport(true)
			setDisabledImage(true)
			setDisabledExcel(true)
		}

		const request: InspectionResultDetailType = {
			clientID:
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? search.clientID
					: userInfo.clientID,
			receptionID,
		}
		console.log(request)
		setGrid2isLoading(true)
		receptionDetailV2(request)
			.then((res) => {
				console.log(res.header)
				console.log(res.list)
				console.log(res.noResultList)

				let containsComment = false
				res?.list.forEach((element: any) => {
					const rtf = element?.ResultRTF
					if (rtf && rtf.includes('#ff0000')) {
						element.isRed = true
					} else {
						element.isRed = false
					}
					if (element?.ResultText) {
						console.log(element)
						containsComment = true
					}
				})

				console.log(containsComment)

				console.table(search)

				setIsContainComment(containsComment)

				// concat res.list and res.noResultList
				const newList = res.list.concat(res.noResultList)
				console.log('newList, newList', newList)
				newList.map((element: any, index: number) => {
					element.rowNum = index
				})
				setRptTypeList(res.rptTypeList)
				setReceptionDetailHeader(res.header)
				setReceptionDetailList(newList)
				setPage2(0)
			})
			.finally(() => {
				setGrid2isLoading(false)
				// window.scrollTo({ top: 0, behavior: 'smooth' })
				grid2Ref.current?.scroll({ top: 0 })
			})
	}

	const [grid1ReceptionID, setGrid1ReceptionID] = useState<number>(0)

	// Grid row click event
	const onRowClick = (e: GridRowModel) => {
		setSlideBlockBtnActive(false)
		setSelectedRowPartName(e?.row?.PartName)
		setSelectedGrid1Row(e.row)
		grid2Ref.current?.setQuickFilterValues([])
		//grid3Ref.current?.setQuickFilterValues([])
		grid2Ref.current?.setPage(0)
		grid2Ref.current?.setSelectionModel([])
		//grid3Ref.current?.setPage(0)
		// 수진자 행 클릭시 검사 결과 항목의 소견이 확장상태이면 닫아준다
		const expandedRows = grid2Ref.current?.getExpandedDetailPanels()
		if (expandedRows?.length > 0) {
			for (let i = 0; i < expandedRows.length; i++) {
				grid2Ref.current?.toggleDetailPanel(expandedRows[i])
			}
		}
		if (e.id) {
			const partCode = e?.row?.PartCode
			const isContainP = ['P0', 'P1', 'P3', 'P4'].includes(partCode)
			console.log(partCode, isContainP)

			if (isContainP) {
				setSlideBlockBtnActive(true)
			} else {
				setSlideBlockBtnActive(false)
			}

			setGrid1ReceptionID(e.row.ReceptionID)
			setReceptionDetailList([])
			getReceptionDetail(e.row.ReceptionID, e.row.RItemID)
			console.log(e.row.ReceptionID)
			console.log(userInfo.clientID)
			console.table(search)
			if (userInfo.infoType === 'C' && e.row.StateCode === 'D90') {
				const request = {
					ReceptionID: e.row.ReceptionID,
					ClientID: userInfo.clientID,
				}
				// 접수 열람 상태 변경
				receptionView(request)
			}
		}
	}

	const [{ w }, setConfig] = useState({ w: 0 })
	const boundaryRef = useRef<HTMLDivElement>(null)

	const inrange = (v: number, min: number, max: number) => {
		if (v < min) return min
		if (v > max) return max
		return v
	}

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		searchClientID = e.ClientID
		setSearch({
			...search,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
	}

	const [searchType, setSearchType] = useState<string>('0')

	const searchTypeOnChange = (e: any) => {
		// 0 : 수진자명, 1 : 차트번호, 3: 담당의명, 4:검사항목
		if (e.target.value === '0') {
			setSearch({
				...search,
				chartNo: '',
				doctorName: '',
				testName: '',
				insuranceCode: '',
				isSearchAll: true,
				searchKeyword: '',
			})
		} else if (e.target.value === '1') {
			setSearch({
				...search,
				patientName: '',
				doctorName: '',
				testName: '',
				insuranceCode: '',
				isSearchAll: false,
				searchKeyword: '',
			})
		} else if (e.target.value === '3') {
			setSearch({
				...search,
				patientName: '',
				chartNo: '',
				testName: '',
				insuranceCode: '',
				isSearchAll: false,
				searchKeyword: '',
			})
		} else if (e.target.value === '4') {
			setSearch({
				...search,
				patientName: '',
				chartNo: '',
				doctorName: '',
				insuranceCode: '',
				isSearchAll: false,
				searchKeyword: '',
			})
		} else if (e.target.value === '5') {
			setSearch({
				...search,
				patientName: '',
				chartNo: '',
				doctorName: '',
				insuranceCode: '',
				isSearchAll: false,
				searchKeyword: '',
			})
		}
		setSearchType(e.target.value)
	}

	const reportPrintAction = (isPrintAction: number) => {
		const datas = grid1Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(datas)
		if (iterArray.length === 0) {
			alertModal('출력할 항목을 선택해주세요.', 'error', () => {})
			return
		} else {
			console.log(iterArray[0])
			const receptionIDs = iterArray.map((element: any) => {
				return element?.ReceptionID
			})
			const receptionIDText = receptionIDs.join(',')
			window.open(
				`${
					process.env.REACT_APP_HOST
				}/rpt/reports?receptionIDs=${receptionIDText}&clientID=${
					userInfo.infoType === 'S' || userInfo.infoType === 'W'
						? search.clientID
						: userInfo.clientID
				}&zoom=100&isPrintAction=${isPrintAction}`,
				'결과보고서',
				'width=900, height=1000'
			)
		}
	}

	const actionReportByRItemID = () => {
		const datas = grid2Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(datas)
		console.log(iterArray)
		if (iterArray.length === 0) {
			alertModal('출력할 항목을 선택해주세요.', 'error', () => {})
			return
		} else {
			console.log(iterArray[0])
			const rItemIDs = iterArray.map((element: any) => {
				return element?.RItemID
			})
			const rItemIDText = rItemIDs.join(',')
			window.open(
				`${process.env.REACT_APP_HOST}/rpt/reportByItem?receptionID=${
					iterArray[0].ReceptionID
				}&rItemIDs=${rItemIDText}&clientID=${
					userInfo.infoType === 'S' || userInfo.infoType === 'W'
						? search.clientID
						: userInfo.clientID
				}&zoom=100`,
				'결과보고서',
				'width=900, height=1000'
			)
		}
	}

	// 최근 2년 검사결과
	const [showPrevReportPopup, setShowPrevReportPopup] = useState(false)
	const [popupDatas, setPopupDatas] = useState<any>([])
	const [selectedRItemID, setSelectedRItemID] = useState<number[]>([])
	const [selectedReceptionRow, setSelectedReceptionRow] = useState<any>({})
	const [slvRows, setSlvRows] = useState<GridRowModel[]>([])

	const [slvColumns, setSlvColumns] = useState<GridColumns>([
		{
			field: 'rowNum',
			headerName: 'No',
			width: 100,
		},
		{
			field: 'RItemID',
			headerName: '접수ID',
			width: 100,
		},
		{
			field: 'InsuranceCode',
			headerName: '보험코드',
			type: 'string',
			width: 120,
			renderCell: (params) => {
				const { RItemID, InsuranceCode } = params.row
				const isContainRItemID = selectedRItemID.includes(RItemID)
				return (
					<Box
						sx={{
							display: 'block',
							width: '100%',
							height: '100%',
						}}
					>
						{isContainRItemID ? <>{InsuranceCode}</> : <>{InsuranceCode}</>}
					</Box>
				)
			},
		},
		{
			field: 'TestName',
			headerName: '검사항목',
			type: 'string',
			width: 200,
			renderCell: (params) => {
				const { RItemID, TestName } = params.row
				const isContainRItemID = selectedRItemID.includes(RItemID)
				return (
					<Box
						sx={{
							display: 'block',
							width: '100%',
							height: '100%',
						}}
					>
						{isContainRItemID ? <>{TestName}</> : <>{TestName}</>}
					</Box>
				)
			},
		},
	])

	const actionPrevReport = () => {
		const selectedRow = grid2Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(selectedRow)
		const request = {
			patientName: receptioDetailHeader?.PatientName,
			chartNo: receptioDetailHeader?.ChartNo,
			socailNumber: receptioDetailHeader?.SocialNumber,
			accuracy: slideAccurate,
			receptionID: iterArray[0]?.ReceptionID,
			clientID:
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? search.clientID
					: userInfo.clientID,
		}
		console.table(request)
		apiReceptionSelectByPatientInfoV2(request).then((res: any) => {
			console.log(res)
			setPopupDatas(res)
			// 팝업에서 그리드 하이라이트용 RItemID 값 배열
			const selectedRItemID = iterArray.map((element: any) => {
				return element?.RItemID
			})
			setSelectedRItemID(selectedRItemID)
			setSelectedReceptionRow(res[0])

			// grid1ReceptionID
			const datas = grid2Ref.current?.getSelectedRows()
			const iterArray2 = valuesInIterator(datas)
			console.log(iterArray2[0])
			const request = {
				receptionIDs: grid1ReceptionID.toString(),
				firstReceptionID: iterArray2[0]?.ReceptionID,
			}
			apiReceptionItemSelectByPatientInfoV2(request).then((res) => {
				let tmp: any[] = []
				res?.headerList.forEach((element: any) => {
					tmp.push({
						field: element,
						headerName: gridColumnHeaderName(element),
						renderCell: (params: GridCellParams) => {
							return <>{params.value}</>
						},
						cellClassName: (params: GridCellParams<any>) => {
							// iterArray2[0].ReceptionDate 와 그리드 컬럼 이름이 같은 경우
							if (params.field === 'date_' + selectedGrid1Row?.ReceptionDate) {
								return 'highlight'
							}
						},
					})
				})
				setSlvColumns(tmp)
				setSlvRows([...res.list])
			})

			setShowPrevReportPopup(true)
		})
	}

	const actionPrevReportClose = () => {
		setShowPrevReportPopup(false)
		setPopupDatas([])
		setSelectedRItemID([])
		setSlvRows([])
		setSelectedReceptionRow({})
		setSlideAccurate(1)
		setSlvColumns([
			{
				field: 'rowNum',
				headerName: 'No',
				width: 100,
			},
			{
				field: 'ReceptionID',
				headerName: '접수ID',
				width: 100,
			},
			{
				field: 'RItemID',
				headerName: '접수ID',
				width: 100,
			},
			{
				field: 'TestCode',
				headerName: '검사코드',
				width: 100,
			},
			{
				field: 'InsuranceCode',
				headerName: '보험코드',
				type: 'string',
				width: 120,
				renderCell: (params) => {
					const { RItemID, InsuranceCode } = params.row
					const isContainRItemID = selectedRItemID.includes(RItemID)
					return (
						<Box
							sx={{
								display: 'block',
								width: '100%',
								height: '100%',
							}}
						>
							{isContainRItemID ? <>{InsuranceCode}</> : <>{InsuranceCode}</>}
						</Box>
					)
				},
			},
			{
				field: 'TestName',
				headerName: '검사항목',
				type: 'string',
				width: 200,
				renderCell: (params) => {
					const { RItemID, TestName } = params.row
					const isContainRItemID = selectedRItemID.includes(RItemID)
					return (
						<Box
							sx={{
								display: 'block',
								width: '100%',
								height: '100%',
							}}
						>
							{isContainRItemID ? <>{TestName}</> : <>{TestName}</>}
						</Box>
					)
				},
			},
		])
	}

	const onPopupMstClick = (selectedRowIds: any) => {
		let receptionIDs: any[] = []
		selectedRowIds.forEach((element: any, index: number) => {
			// popupDatas 에서 rowNum이 동일한 항목의 ReceptionID를 찾아서 recpetionIDs에 추가
			const receptionID = popupDatas.find(
				(item: any) => item.rowNum === element
			)?.ReceptionID
			if (receptionID) {
				receptionIDs.push(receptionID)
			}
		})
		// receptionIDs에 첫번째 선택된 접수ID 추가 ( selectionModel에서 disabled된 객체도 같이 가져오기 때문에 아래 코드 불필요 )
		// receptionIDs.unshift(grid1ReceptionID)

		const receptionIDString = receptionIDs.join(',')

		const datas = grid2Ref.current?.getSelectedRows()
		const iterArray2 = valuesInIterator(datas)
		console.log(iterArray2[0])

		console.log(grid1Ref.current?.getSelectedRows())
		if (receptionIDString) {
			const request = {
				receptionIDs: receptionIDString,
				firstReceptionID: iterArray2[0]?.ReceptionID,
			}
			apiReceptionItemSelectByPatientInfoV2(request).then((res) => {
				let tmp: any[] = []
				res?.headerList.forEach((element: any) => {
					tmp.push({
						field: element,
						headerName: gridColumnHeaderName(element),
						renderCell: (params: GridCellParams) => {
							return <>{params.value}</>
						},
						cellClassName: (params: GridCellParams<any>) => {
							// iterArray2[0].ReceptionDate 와 그리드 컬럼 이름이 같은 경우
							if (params.field === 'date_' + selectedGrid1Row?.ReceptionDate) {
								return 'highlight'
							}
						},
					})
				})
				setSlvColumns(tmp)
				setSlvRows([...res.list])
			})
		}
	}

	const gridColumnHeaderName = (headerName: string) => {
		if (headerName === 'InsuranceCode') {
			return '보험코드'
		} else if (headerName === 'TestName') {
			return '검사항목'
		} else if (headerName.startsWith('date_')) {
			return headerName.split('_')[1]
		}
	}

	const [slideAccurate, setSlideAccurate] = useState<number>(1)
	const accuracyOnChange = (e: any, selectedRows: any[]) => {
		let tmpSlideAccurate = 0
		switch (e.target.value) {
			case 0:
				tmpSlideAccurate = 0
				setSlideAccurate(0)
				break
			case 50:
				tmpSlideAccurate = 1
				setSlideAccurate(1)
				break
			case 100:
				tmpSlideAccurate = 2
				setSlideAccurate(2)
				break
			default:
				tmpSlideAccurate = 0
				setSlideAccurate(0)
				break
		}
		const selectedRow = grid2Ref.current?.getSelectedRows()
		const iterArray = valuesInIterator(selectedRow)
		const request = {
			patientName: receptioDetailHeader?.PatientName,
			chartNo: receptioDetailHeader?.ChartNo,
			socailNumber: receptioDetailHeader?.SocialNumber,
			accuracy: tmpSlideAccurate,
			receptionID: iterArray[0]?.ReceptionID,
			clientID:
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? search.clientID
					: userInfo.clientID,
		}
		console.table(request)
		apiReceptionSelectByPatientInfoV2(request).then((res: any) => {
			console.log(res)
			setPopupDatas(res)
			// 팝업에서 그리드 하이라이트용 RItemID 값 배열
			const selectedRItemID = iterArray.map((element: any) => {
				return element?.RItemID
			})
			setSelectedRItemID(selectedRItemID)
			setSelectedReceptionRow(res[0])
			// 팝업에서 선택한 그리드 목록의 receptionID값을 가져온다.
			let receptionIDs: any[] = []
			selectedRows.forEach((element: any) => {
				const { ReceptionID } = element
				receptionIDs.push(ReceptionID)
			})
			const receptionIDString = receptionIDs.join(',')
			// grid1ReceptionID
			const datas = grid2Ref.current?.getSelectedRows()
			const iterArray2 = valuesInIterator(datas)
			console.log(iterArray2[0])
			const request = {
				receptionIDs: receptionIDString,
				firstReceptionID: iterArray2[0]?.ReceptionID,
			}
			console.log(selectedGrid1Row)
			apiReceptionItemSelectByPatientInfoV2(request).then((res) => {
				console.log(res)
				let tmp: any[] = []
				res?.headerList.forEach((element: any) => {
					tmp.push({
						field: element,
						headerName: gridColumnHeaderName(element),
						renderCell: (params: GridCellParams) => {
							return <>{params.value}</>
						},
						cellClassName: (params: GridCellParams<any>) => {
							// iterArray2[0].ReceptionDate 와 그리드 컬럼 이름이 같은 경우
							if (params.field === 'date_' + selectedGrid1Row?.ReceptionDate) {
								return 'highlight'
							}
						},
					})
				})
				setSlvColumns(tmp)
				setSlvRows([...res.list])
			})

			setShowPrevReportPopup(true)
		})
	}

	const [slideBlockBtnActive, setSlideBlockBtnActive] = useState(false)
	const [slideBlickPopupisOpen, setSlideBlickPopupisOpen] = useState(false)
	const [popupData, setPopupData] = useState<any>({})
	const [sbList, setSbList] = useState<any>([])
	const [selectedSbList, setSelectedSbList] = useState<any>([])

	const onSlideBlockPopupClose = () => {
		setSlideBlickPopupisOpen(false)
		setPopupData({})
	}

	const onSlideBlockItemClick = (item: any) => {
		// selectedSbList에 동일한 rowNum이 없으면 추가 있으면 삭제
		const index = selectedSbList.findIndex((x: any) => x.rowNum === item.rowNum)
		if (index !== -1) {
			setSelectedSbList(
				selectedSbList.filter((x: any) => x.rowNum !== item.rowNum)
			)
		} else {
			setSelectedSbList([...selectedSbList, item])
		}
	}

	const onSlideBlockPopupSubmit = () => {
		if (selectedSbList.length === 0) {
			alertModal('Slide/Block을 선택해주세요.', 'error', () => {})
			return
		}
		if (!popupData?.agree) {
			alertModal(
				'"확인했습니다."에 확인 후 신청하실 수 있습니다.',
				'error',
				() => {}
			)
			return
		}
		if (popupData?.agree && selectedSbList.length > 0) {
			confirmAlert(
				'저장하시겠습니까?',
				() => {
					const data: any[] = []
					console.log(selectedSbList)
					const dataObject = {
						ReceptionID: popupData?.ReceptionID,
						ReTestCode: selectedSbList.map((item: any) => item.TestCode).join(','),
						ClientID:
							userInfo?.infoType === 'C' ? userInfo?.clientID : search?.clientID,
						RequestCode: popupData?.PartCode || '',
						RequestNotes: popupData?.RequestNotes || '',
						TRequestType: 'L',
						RItemID: popupData?.RItemID,
						SContainerSNames: popupData?.ContainerSName || '',
						BranchCode: popupData?.BranchCode || '',
						ManagerID: popupData?.ManagerID || '',
						LabCode: popupData?.LabCode || '',
						TeamCodes: popupData?.TeamCode || '',
						TestCode:
							selectedSbList.map((item: any) => item.TestCode).join(',') || '',
						RowCrud: 'C',
					}

					data.push(dataObject)

					const request = {
						clientID:
							userInfo?.infoType === 'C' ? userInfo?.clientID : search?.clientID,
						json: [...data],
					}
					console.log(request)

					if (!request.clientID) {
						alertModal('거래처코드가 없습니다.', 'error', () => {})
						return
					}

					if (request.json.length === 0) {
						alertModal('신청할 Slide/Block을 선택해주세요.', 'error', () => {})
						return
					}

					console.log(request)

					apiSBReserve(request).then((res) => {
						console.log(res)
						timerAlert('신청되었습니다.', 'success', () => {})
						onSlideBlockPopupClose()
						setPopupData({})
						setSelectedSbList([])
					})
				},
				() => {}
			)
		}
	}

	// 슬라이드블럭 대여신청
	const actionSlideBlock = () => {
		if (!slideBlockBtnActive) return
		const iterArray = selectedGrid1Row

		console.log(selectedGrid1Row)

		// 내부사용자이고 clientID가 없으면
		if (
			(userInfo.infoType === 'S' || userInfo.infoType === 'W') &&
			search.clientID === ''
		) {
			alertModal('거래처를 선택해주세요.', 'error', () => {})
			return
		}

		if (!iterArray?.ReceptionID) {
			alertModal('접수ID가 없습니다.', 'error', () => {})
			return
		}

		const request = {
			clientID: search.clientID as any,
			patientName: search.patientName,
			chartNo: search.chartNo,
			startDate: moment(search.startDate).isBefore('2023-08-01')
				? '2023-08-01'
				: search.startDate,
			endDate: search.endDate,
			socialNumber: '',
			pathologyNo: '',
			status: '0',
			step: '0',
			searchType: search.searchType,
			receptionID: iterArray?.ReceptionID,
		}

		console.table(request)

		apiSBReceptionList(request)
			.then((res) => {
				console.log(res)
				if (res.length > 0) {
					setPopupData(res[0])
					setSlideBlickPopupisOpen(true)
				} else {
					alertModal('Slide/Block 정보가 없습니다.', 'error', () => {})
				}
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {})
	}

	// 이전 결과 팝업
	const [prevResultDialog, setPrevResultDialog] = useState(false)
	const [prevResultList, setPrevResultList] = useState<any[]>([])
	const getPrevResult = async (
		PatientName: string,
		SocialNumber: string,
		TestCode: string
	) => {
		// searchClientID는 상단에 별도로 변수 선언함... 이유는 memo때문인지 search.clientID를 사용하면 값이 안바뀜

		const request = {
			PatientName: PatientName,
			SocialNumber: SocialNumber,
			TestCode: TestCode,
			clientID:
				userInfo.infoType === 'S' || userInfo.infoType === 'W'
					? searchClientID
					: userInfo.clientID,
		}

		console.table(search)

		console.table(request)

		prevReceptionChartData(request).then((res) => {
			console.log(res)
			if (res.length > 0) {
				// res에 Reference가 존재할 경우 ~ 기호 기준으로 잘라서 배열로 만들고 좌측의 값은 ref1 우측은 ref2로 저장
				res.forEach((element: any) => {
					if (element.Reference) {
						// const ref = element.Reference.split('~')
						// element.ref1 = ref[0]?.trim()
						// element.ref2 = ref[1]?.trim()
						// element.ref1Hidden = element.ref1 ? false : true
						// element.ref2Hidden = element.ref2 ? false : true

						const text = element.Reference
						const ref = element.Reference.split('~')
						const match = text.match(/:?\s*(\d+(\.\d+)?[-~]\d+(\.\d+)?)/)

						if (match) {
							// match[1] split with ~ or -
							const range = match[1].split(/[-~]/)
							element.ref1 = range[0]
							element.ref2 = range[1]
						} else {
							element.ref1 = ref[0]?.trim()
							element.ref2 = ref[1]?.trim()
						}
						element.ref1Hidden = element.ref1 ? false : true
						element.ref2Hidden = element.ref2 ? false : true

						// ref1과 ref2에 ≤ 기호가 있을 경우 삭제
						if (element.ref1.includes('≤')) {
							element.ref1 = element.ref1.replace('≤', '')
						}

						console.table(element)
					}
				})
				console.log(res)
				setPrevResultList(res)
				setPrevResultDialog(true)
			} else {
				alertModal('이전 결과가 없습니다.', 'info', () => {})
			}
		})
	}

	const prevResultClose = () => {
		setPrevResultList([])
		setPrevResultDialog(false)
	}

	useEffect(() => {
		// if (userInfo?.infoType !== 'S' && userInfo?.infoType !== 'W') {
		// 	alertModal('권한이 없습니다.', 'error', () => {
		// 		navigate('/admin/test/result')
		// 	})
		// }
		if (
			(userInfo?.infoType === 'C' && userInfo.isEHWAClass) ||
			userInfo?.infoType === 'S' ||
			userInfo?.infoType === 'W'
		) {
		} else {
			// alertModal('권한이 없습니다.', 'error', () => {})
			// navigate('/admin/test/result')
		}
		// 사용자 타입이 거래처일 경우만 자동 조회
		if (userInfo.infoType === 'C') getReceptionList()
		// 검색조건의 공통코드
		const lv2 = extractCmmnCode(cmmnCdList, 'TEST_CD', 1)
		setTestCdList(lv2?.level2)
		// 사용자 타입이 거래처일 경우만 자동 조회
		if (userInfo.infoType === 'C') {
			setTimeout(() => {
				getReceptionList()
			}, 500)
		}
		apiSBList().then((res) => {
			// let resultList: [] = []
			// if (userInfo?.isEhwa) {
			// 	resultList = res.filter((x: any) => x.DisplayName.includes('이화'))
			// } else {
			// 	resultList = res.filter((x: any) => !x.DisplayName.includes('이화'))
			// }
			// setSbList(resultList)
			setSbList(res)
		})
	}, [])

	const handleEvent: GridEventListener<'cellKeyDown'> = (
		params, // GridCellParams<any>
		event, // MuiEvent<React.KeyboardEvent<HTMLElement>>
		details // GridCallbackDetails
	) => {
		if (event.code === 'ArrowDown') {
			const nextRow = grid1Ref.current?.getRow(params.row?.rowNum + 1)
			if (nextRow) {
				grid1Ref.current?.setSelectionModel([nextRow.rowNum])
			}
		} else if (event.code === 'ArrowUp') {
			const prevRow = grid1Ref.current?.getRow(params.row?.rowNum - 1)
			if (prevRow) {
				grid1Ref.current?.setSelectionModel([prevRow.rowNum])
			}
		} else if (
			(event.code === 'Enter' && selectionModel.length > 0) ||
			(event.code === 'NumpadEnter' && selectionModel.length > 0)
		) {
			setSlideBlockBtnActive(false)
			setSelectedRowPartName(params?.row?.PartName)
			setSelectedGrid1Row(params.row)
			grid2Ref.current?.setQuickFilterValues([])
			//grid3Ref.current?.setQuickFilterValues([])
			grid2Ref.current?.setPage(0)
			grid2Ref.current?.setSelectionModel([])
			//grid3Ref.current?.setPage(0)
			// 수진자 행 클릭시 검사 결과 항목의 소견이 확장상태이면 닫아준다
			const expandedRows = grid2Ref.current?.getExpandedDetailPanels()
			if (expandedRows?.length > 0) {
				for (let i = 0; i < expandedRows.length; i++) {
					grid2Ref.current?.toggleDetailPanel(expandedRows[i])
				}
			}
			if (params.id) {
				const partCode = params?.row?.PartCode
				const isContainP = ['P0', 'P1', 'P3', 'P4'].includes(partCode)
				console.log(partCode, isContainP)

				if (isContainP) {
					setSlideBlockBtnActive(true)
				} else {
					setSlideBlockBtnActive(false)
				}

				setGrid1ReceptionID(params.row.ReceptionID)
				setReceptionDetailList([])
				getReceptionDetail(params.row.ReceptionID, params.row.RItemID)
				console.log(params.row.ReceptionID)
				console.log(userInfo.clientID)
				console.table(search)
				if (userInfo.infoType === 'C' && params.row.StateCode === 'D90') {
					const request = {
						ReceptionID: params.row.ReceptionID,
						ClientID: userInfo.clientID,
					}
					// 접수 열람 상태 변경
					receptionView(request)
				}
			}
		}
	}

	useEffect(() => {
		const boundary = boundaryRef.current?.getBoundingClientRect()

		if (boundary) {
			const DEFAULT_W = 440
			setConfig({
				w: DEFAULT_W,
			})
		}
	}, [])

	return (
		<>
			<LocalizationProvider
				dateAdapter={AdapterDayjs}
				adapterLocale={locale}
			>
				<ResultV2Popup
					dialogOpen={showPrevReportPopup}
					dialogClose={actionPrevReportClose}
					popupDatas={popupDatas}
					selectedRItemID={selectedRItemID}
					onPopupMstClick={onPopupMstClick}
					slvRows={slvRows}
					slvColumns={slvColumns}
					selectedReceptionRow={selectedReceptionRow}
					grid1ReceptionID={grid1ReceptionID}
					accuracyOnChange={accuracyOnChange}
				/>
				<ClientSearchPopup
					dialogOpen={clientDialogOpen}
					dialogClose={clientDialogClose}
					popupSubmit={clientPopupSubmit}
				/>
				<SlideBlockPopup
					popupOpen={slideBlickPopupisOpen}
					popupData={popupData}
					setPopupData={setPopupData}
					selectedSbList={selectedSbList}
					sbList={sbList}
					onPopupClose={onSlideBlockPopupClose}
					onSBItemClick={onSlideBlockItemClick}
					onPopupSubmit={onSlideBlockPopupSubmit}
				/>
				<PrevResult
					prevResultList={prevResultList}
					prevResultClose={prevResultClose}
					prevResultDialog={prevResultDialog}
					patientInfo={receptioDetailHeader}
				/>
				<Stack
					sx={{
						position: 'relative',
						zIndex: 100,
						padding: '0.5rem',
						borderRadius: '0.5rem',
						color: '#fff',
						backgroundColor:
							theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#3C4F8F',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexDirection: 'row',
						'@media (max-width: 1023px)': {
							position: 'relative',
							paddingTop: '15px',
							marginTop: '20px',
							top: '0px',
						},
					}}
				>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						onKeyUp={onKeyUp}
					>
						<Grid container>
							<Grid
								item
								md={5}
							>
								<SearchContainer>
									<Grid
										container
										spacing={0.3}
									>
										<Grid
											item
											xs={4}
										>
											<DatePickerWrapper>
												<DatePicker
													value={search.startDate}
													mask={'____-__-__'}
													inputFormat={'YYYY-MM-DD'}
													onChange={(newValue) => {
														dateRanageOnChange(newValue, 'startDate')
													}}
													renderInput={(params) => (
														<TextField
															size="small"
															{...params}
															fullWidth
														/>
													)}
												/>
											</DatePickerWrapper>
										</Grid>
										<Grid
											item
											xs={4}
										>
											<DatePickerWrapper>
												<DatePicker
													value={search.endDate}
													mask={'____-__-__'}
													inputFormat={'YYYY-MM-DD'}
													onChange={(newValue) => {
														dateRanageOnChange(newValue, 'endDate')
													}}
													PopperProps={{
														sx: {
															'& > *': {
																backgroundColor: 'red',
																border: '1px solid black',
															},
														},
													}}
													renderInput={(params) => (
														<TextField
															size="small"
															{...params}
															fullWidth
														/>
													)}
												/>
											</DatePickerWrapper>
										</Grid>
										<Grid
											item
											xs={4}
											sx={{ alignItems: 'center', display: 'flex' }}
										>
											<FormButtons>
												<div className="checkWrapper">
													<Tooltip title="기간무시">
														<input
															type="checkbox"
															value={search.ignoreDate}
															onChange={() => {
																setSearch({
																	...search,
																	ignoreDate: search.ignoreDate === '0' ? '1' : '0',
																})
															}}
														/>
													</Tooltip>
												</div>
												<Tooltip title="지난달">
													<Btn
														type="button"
														onClick={() => {
															// startDate, endDate 날짜 차이를 계산해서 변경 예를 들어 startDate가 2024-06-07, endDate 2024-06-08 이면 날짜 차이가 이틀이므로 해당 이벤트 발생시 2024-06-05, 2024-06-06로 변경
															const diff = moment(search.endDate).diff(
																search.startDate,
																'days'
															)
															setSearch({
																...search,
																startDate: moment(search.startDate)
																	.subtract(diff + 1, 'days')
																	.format('YYYY-MM-DD'),
																endDate: moment(search.endDate)
																	.subtract(diff + 1, 'days')
																	.format('YYYY-MM-DD'),
															})
														}}
													>
														<ChevronLeftIcon />
													</Btn>
												</Tooltip>
												<Tooltip title="다음달">
													<Btn
														type="button"
														onClick={() => {
															const diff = moment(search.endDate).diff(
																search.startDate,
																'days'
															)
															setSearch({
																...search,
																startDate: moment(search.startDate)
																	.add(diff + 1, 'days')
																	.format('YYYY-MM-DD'),
																endDate: moment(search.endDate)
																	.add(diff + 1, 'days')
																	.format('YYYY-MM-DD'),
															})
														}}
													>
														<ChevronRightIcon />
													</Btn>
												</Tooltip>
												<Tooltip title="오늘">
													<Btn
														type="button"
														onClick={() => {
															setSearch({
																...search,
																startDate: moment().format('YYYY-MM-DD'),
																endDate: moment().format('YYYY-MM-DD'),
															})
														}}
													>
														<TodayIcon />
													</Btn>
												</Tooltip>
												<Tooltip title="이번달">
													<Btn
														type="button"
														onClick={() => {
															// set startDate and endDate to this month
															setSearch({
																...search,
																startDate: moment().startOf('month').format('YYYY-MM-DD'),
																endDate: moment().endOf('month').format('YYYY-MM-DD'),
															})
														}}
													>
														<CalendarMonthIcon />
													</Btn>
												</Tooltip>
											</FormButtons>
										</Grid>
										<Grid
											item
											xs={4}
										>
											<InputWrapper>
												<FormControl fullWidth>
													<Select
														onChange={(e: SelectChangeEvent) => {
															console.log(e.target.value)
															setSearch({
																...search,
																searchTestCd: e.target.value,
															})
														}}
														displayEmpty
														inputProps={{ 'aria-label': '보고서 종류' }}
														size="small"
														value={search.searchTestCd}
													>
														<MenuItem value={'0'}>전체보고서</MenuItem>
														{testCdList &&
															testCdList.length > 0 &&
															testCdList.map(
																(item: { id: number; cmmnCd: string; cmmnNm: string }) => (
																	<MenuItem
																		key={item.id}
																		value={item?.cmmnCd}
																	>
																		{item?.cmmnNm === 'STD/HPV' ? '분자진단' : item?.cmmnNm}
																	</MenuItem>
																)
															)}
													</Select>
												</FormControl>
											</InputWrapper>
										</Grid>
										<Grid
											item
											xs={4}
										>
											<InputWrapper>
												<FormControl fullWidth>
													<Select
														value={searchType}
														onChange={searchTypeOnChange}
														displayEmpty
														inputProps={{ 'aria-label': '검색 종류' }}
														size="small"
													>
														<MenuItem value={'0'}>전체</MenuItem>
														<MenuItem value={'1'}>수검자</MenuItem>
														<MenuItem value={'2'}>차트번호</MenuItem>
														<MenuItem value={'3'}>담당의명</MenuItem>
														<MenuItem value={'4'}>검사항목</MenuItem>
														<MenuItem value={'5'}>보험코드</MenuItem>
													</Select>
												</FormControl>
											</InputWrapper>
										</Grid>
										<Grid
											item
											xs={4}
										>
											<InputWrapper>
												{searchType === '0' && (
													<TextField
														size="small"
														value={search.patientName}
														onChange={(e) => {
															setSearch({
																...search,
																patientName: e.target.value,
																searchKeyword: e.target.value,
															})
														}}
														fullWidth
														inputProps={{ placeholder: '수검자,차트,담당의,검사명,보험코드' }}
													/>
												)}
												{searchType === '1' && (
													<TextField
														size="small"
														value={search.patientName}
														onChange={(e) => {
															setSearch({
																...search,
																patientName: e.target.value,
															})
														}}
														fullWidth
														inputProps={{ placeholder: '수검자' }}
													/>
												)}
												{searchType === '2' && (
													<TextField
														size="small"
														value={search.chartNo}
														onChange={(e) => {
															setSearch({
																...search,
																chartNo: e.target.value,
															})
														}}
														fullWidth
														inputProps={{ placeholder: '차트번호' }}
													/>
												)}
												{searchType === '3' && (
													<TextField
														size="small"
														value={search.doctorName}
														onChange={(e) => {
															setSearch({
																...search,
																doctorName: e.target.value,
															})
														}}
														fullWidth
														inputProps={{ placeholder: '담당의' }}
													/>
												)}
												{searchType === '4' && (
													<TextField
														size="small"
														value={search.testName}
														onChange={(e) => {
															setSearch({
																...search,
																testName: e.target.value,
															})
														}}
														fullWidth
														inputProps={{ placeholder: '검사명' }}
													/>
												)}
												{searchType === '5' && (
													<TextField
														size="small"
														value={search.insuranceCode}
														onChange={(e) => {
															setSearch({
																...search,
																insuranceCode: e.target.value,
															})
														}}
														fullWidth
														inputProps={{ placeholder: '보험코드' }}
													/>
												)}
											</InputWrapper>
										</Grid>
									</Grid>
									<Button
										variant="contained"
										color="primary"
										startIcon={<SearchIcon />}
										sx={{ marginLeft: '5px' }}
										onClick={actionSearch}
										disabled={isSearchLoading ? true : false}
									>
										검색
									</Button>
								</SearchContainer>
							</Grid>
							<Grid
								item
								md={7}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									pl: '5px',
								}}
							>
								<ReportButtonContainer>
									<Button
										variant="contained"
										color="primary"
										size="large"
										sx={{
											marginRight: '5px',
											'&.Mui-disabled': {
												color: '#787878',
											},
										}}
										onClick={() => reportPrintAction(1)}
										disabled={selectionModel.length === 0 ? true : false}
									>
										<img
											src={PrintImg}
											alt="print"
											style={{ marginRight: '5px' }}
										/>
										현재 보고서 인쇄
									</Button>
									<Button
										variant="contained"
										color="primary"
										size="large"
										sx={{
											marginRight: '5px',
											'&.Mui-disabled': {
												color: '#787878',
											},
										}}
										onClick={() => reportPrintAction(0)}
										disabled={selectionModel.length === 0 ? true : false}
									>
										<img
											src={PrintAreaImg}
											alt="print"
											style={{ marginRight: '5px' }}
										/>
										보고서 미리보기
									</Button>
									<Button
										variant="contained"
										color="primary"
										size="large"
										sx={{
											marginRight: '5px',
											'&.Mui-disabled': {
												color: '#787878',
											},
										}}
										onClick={actionReportByRItemID}
										disabled={subSelectionModel.length === 0 ? true : false}
									>
										<img
											src={SelecedtPrintImg}
											alt="print"
											style={{ marginRight: '5px' }}
										/>
										선택결과 미리보기
									</Button>
									<Button
										variant="contained"
										color="primary"
										size="large"
										sx={{
											marginRight: '5px',
											'&.Mui-disabled': {
												color: '#787878',
											},
										}}
										onClick={actionExcel}
										disabled={selectionModel.length === 0 ? true : false}
									>
										<img
											src={ExportToXLSXImg}
											alt="print"
											style={{ marginRight: '5px' }}
										/>
										결과엑셀 변환
									</Button>
									<Button
										variant="contained"
										color="primary"
										size="large"
										sx={{
											marginRight: '5px',
											'&.Mui-disabled': {
												color: '#787878',
											},
										}}
										onClick={actionPrevReport}
										disabled={subSelectionModel.length === 0 ? true : false}
									>
										<img
											src={ResultCompareImg}
											alt="print"
											style={{ marginRight: '5px' }}
										/>
										이전검사 결과
									</Button>
									<Button
										color="secondary"
										size="small"
										variant="contained"
										sx={{
											marginRight: '5px',
											'&.Mui-disabled': {
												color: '#787878',
											},
										}}
										onClick={() => {
											navigate('/admin/test/sbResult')
										}}
									>
										<img
											src={SlSearchImg}
											alt="슬라이드대여조회"
											style={{ marginRight: '5px' }}
										/>
										슬라이드대여조회
									</Button>
									<Button
										variant="contained"
										color="primary"
										size="large"
										sx={{
											'&.Mui-disabled': {
												color: '#787878',
											},
										}}
										onClick={actionSlideBlock}
										disabled={selectedGrid1Row && slideBlockBtnActive ? false : true}
									>
										<img
											src={PuzzleImg}
											alt="슬라이드 대여신청"
											style={{ marginRight: '5px', width: 32 }}
										/>
										슬라이드대여신청
									</Button>
									{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
										<TextField
											variant="outlined"
											type="text"
											aria-readonly={true}
											value={searchClientName}
											onClick={clientPopupOpenAction}
											placeholder="거래처 선택"
											sx={{
												ml: '5px',
												'& .MuiInputBase-input': {
													backgroundColor: '#fff',
												},
											}}
										/>
									)}
								</ReportButtonContainer>
							</Grid>
						</Grid>
					</Box>
				</Stack>
				<PageBg>
					<DragWrapper ref={boundaryRef}>
						<div
							style={{
								width: isSmDown ? '100%' : w,
								left: 0,
								top: 0,
								position: 'relative',
								height: isSmDown ? 'auto' : '100%',
								zIndex: 2,
							}}
							{...registMouseDownDrag((deltaX, deltaY) => {
								if (!boundaryRef.current) return
								setConfig({
									w,
								})
							})}
						>
							<div style={{ width: isSmDown ? 'auto' : w, height: '100%' }}>
								<DataGridPremium
									apiRef={grid1Ref}
									page={page1}
									onPageChange={(newPage) => setPage1(newPage)}
									rows={receptionListData}
									getRowId={(row) => row.rowNum}
									columns={columns}
									pageSize={size1}
									onPageSizeChange={(newPageSize) => setSize1(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
									pagination
									// autoHeight={false}
									autoHeight={isMdUp ? false : true}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										LoadingOverlay: LinearProgress,
										Toolbar: GridToolbar,
									}}
									loading={grid1isLoading}
									selectionModel={selectionModel}
									onSelectionModelChange={(newSelection) => {
										// 5개 이상 선택 불가
										if (newSelection.length > 5) {
											alertModal('5개 이상 선택할 수 없습니다.', 'error', () => {})
											return
										}
										setSelectionModel(newSelection)
									}}
									onSortModelChange={() => {
										setSelectionModel([])
										setTimeout(() => {
											// receptionListData의 0번째를 selectionModel에 넣어준다.
											console.log(receptionListData[0])
											if (receptionListData.length > 0) {
												const rowNum = receptionListData[0]?.rowNum // ?. 사용
												if (rowNum !== undefined && rowNum !== null) {
													grid1Ref.current.setCellFocus(rowNum, 'rowNum')
												}
											}
										}, 0)
									}} // 정렬 시 selectionModel 초기화
									onRowClick={(e) => {
										onRowClick(e)
									}}
									getRowClassName={(params) => {
										if (params.row?.ReceptionID === grid1ReceptionID) {
											return 'activeRow'
										}
										return ''
									}}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: gridColumnLeftVisible
												? {
														...gridColumnLeftVisible,
														rowNum: false,
														ReceptionID: false,
														BirthDay: false,
														DoctorName: false,
														PartCode: false,
												  }
												: {
														rowNum: false,
														ReceptionID: false,
														BirthDay: false,
														DoctorName: false,
														PartCode: false,
												  },
											orderedFields: [...columnLeftOrder],
										},
									}}
									checkboxSelection
									disableSelectionOnClick
									rowHeight={40}
									onColumnOrderChange={(e) => handleColumnReorder('left', e)}
									columnVisibilityModel={columnVisibilityModel}
									onColumnVisibilityModelChange={(newModel) =>
										setColumnVisibilityModel(newModel)
									}
									// onColumnVisibilityModelChange={(newModel) =>
									// 	handleColumnVisibleChange('left', newModel)
									// }
									sx={{
										overflowX: 'scroll',
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											fontWeight: '500',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
										// '& .MuiDataGrid-cell:focus': {
										// 	outline: 'none',
										// },
										// '& .MuiDataGrid-cell:focus-within': {
										// 	outline: 'none',
										// },
										'& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
											backgroundColor: 'rgba(75, 123, 236, 0.15)',
										},
										'& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover, & .MuiDataGrid-row.Mui-selected.Mui-hovered':
											{
												backgroundColor: 'rgba(75, 123, 236, 0.3)',
											},
										'& .MuiDataGrid-columnHeader': {
											backgroundColor: '#3C4F8F',
											color: '#fff',
										},
										'& .MuiIconButton-root': {
											// color: '#fff',
										},
										'& .MuiDataGrid-columnHeaders': { backgroundColor: '#3C4F8F' },
										'& .activeRow': {
											backgroundColor: 'rgba(75, 207, 250,0.5)',
										},
									}}
									onCellKeyDown={handleEvent}
								/>
							</div>
							<div
								// 3️⃣
								style={{
									position: 'absolute',
									top: '0rem',
									right: '0rem',
									height: '100%',
									width: '5px',
									cursor: 'col-resize',
									background: '#ccc',
									zIndex: 10,
									display: isSmDown ? 'none' : 'block',
								}}
								{...registMouseDownDrag((deltaX, deltaY) => {
									if (!boundaryRef.current) return

									// 3️⃣
									const boundary = boundaryRef.current.getBoundingClientRect()
									setConfig({
										w: inrange(w + deltaX, 80, boundary.width - 12),
									})
									// 2️⃣
								}, true)}
							/>
						</div>
						<div
							style={{
								paddingLeft: isSmDown ? '0' : w,
								position: isSmDown ? 'relative' : 'absolute',
								top: isSmDown ? 'auto' : 0,
								left: isSmDown ? 'auto' : 0,
								right: isSmDown ? 'auto' : 0,
								height: '100%',
								marginTop: isSmDown ? '30px' : 0,
							}}
						>
							<PatientInfoContainer>
								<div
									style={{
										// width: isSmDown ? '340px' : 'auto',
										overflowX: 'auto',
									}}
								>
									<HeaderTableContaienr>
										<HeaderTable>
											<colgroup>
												<col style={{ width: '100px' }} />
												<col style={{ width: '200px' }} />
												<col style={{ width: '100px' }} />
												<col style={{ width: '200px' }} />
												<col style={{ width: '100px' }} />
												<col style={{ width: '200px' }} />
											</colgroup>
											<tbody>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															수진자명
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.PatientName}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															기관명
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ClientName}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															접수번호
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ReceptionInfo}
														</Typography>
													</td>
												</tr>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															차트번호
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ChartNo}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															담당의사
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.DoctorName}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															병리번호
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.PathologyNo}
														</Typography>
													</td>
												</tr>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															생년월일
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.SocialNumber &&
															receptioDetailHeader?.SocialNumber.length === 8 ? (
																receptioDetailHeader?.SocialNumber.substring(0, 6)
															) : (
																<></>
															)}
															{receptioDetailHeader?.PatientAge &&
																` (${receptioDetailHeader?.PatientAge})`}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															진료과/병동
														</Typography>
													</th>
													<td colSpan={3}>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.DepartName &&
															receptioDetailHeader?.Ward ? (
																<>
																	{receptioDetailHeader?.DepartName}/{receptioDetailHeader?.Ward}
																</>
															) : (
																<>
																	{receptioDetailHeader?.DepartName}
																	{receptioDetailHeader?.Ward}
																</>
															)}
														</Typography>
													</td>
												</tr>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															의뢰일
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.ReceptionDate}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															보고일
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.LastStateDate}
														</Typography>
													</td>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															채취일
														</Typography>
													</th>
													<td>
														<Typography
															variant="subtitle1"
															component="p"
														>
															{receptioDetailHeader?.CollectedDate
																? receptioDetailHeader?.CollectedDate.substring(0, 10)
																: ''}
														</Typography>
													</td>
												</tr>

												<tr></tr>
												<tr>
													<th>
														<Typography
															variant="subtitle1"
															component="p"
														>
															임상정보
														</Typography>
													</th>
													<td colSpan={5}>
														<Typography
															variant="subtitle1"
															component="p"
														></Typography>
													</td>
												</tr>
											</tbody>
										</HeaderTable>
									</HeaderTableContaienr>
								</div>
							</PatientInfoContainer>
							<Box
								sx={{
									marginTop: '0.5rem',
									height: 'calc(100vh - 396px)',
									// height: isLgUp ? 'calc(100vh - 396px)' : 'auto',
									// '@media (max-width: 1023px)': {
									// 	height: '400px',
									// 	overflow: 'auto',
									// },
								}}
							>
								<DataGridPremium
									apiRef={grid2Ref}
									page={page2}
									onPageChange={(newPage) => setPage2(newPage)}
									rows={receptionDetailList}
									getRowId={(row) => row.rowNum}
									// columns={columnsRightMemo}
									columns={
										selectedRowPartName === '조직' || selectedRowPartName === '세포'
											? columnsRightP0P1Memo
											: columnsRightMemo
									}
									pageSize={size2}
									onPageSizeChange={(newPageSize) => setSize2(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000]}
									pagination
									// autoHeight={false}
									autoHeight={isLgUp ? false : true}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									components={{
										LoadingOverlay: LinearProgress,
										Toolbar: GridToolbar,
									}}
									loading={grid2isLoading}
									selectionModel={subSelectionModel}
									onSelectionModelChange={(newSelection) => {
										setSubSelectionModel(newSelection)
									}}
									onRowClick={() => {
										// !disableRowClick && actionReport()
									}}
									rowThreshold={0}
									getRowHeight={() => 'auto'}
									density={(env.desnse as GridDensity) || 'compact'}
									// initialState={{
									// 	columns: {
									// 		columnVisibilityModel: {
									// 			PartName: false,
									// 			DepartName: false,
									// 			ReceptionID: false,
									// 			isPrint: false,
									// 			PartCode: false,
									// 			rowNum: false,
									// 		},
									// 	},
									// }}
									initialState={{
										columns: {
											// columnVisibilityModel: gridColumnRightVisible
											// 	? {
											// 			...gridColumnRightVisible,
											// 			rowNum: false,
											// 			ReceptionID: false,
											// 	  }
											// 	: {
											// 			rowNum: false,
											// 			ReceptionID: false,
											// 	  },
											orderedFields: [...columnRightOrder],
										},
									}}
									checkboxSelection
									rowHeight={40}
									onColumnOrderChange={(e) => handleColumnReorder('right', e)}
									onColumnVisibilityModelChange={(newModel) =>
										handleColumnVisibleChange('right', newModel)
									}
									sx={{
										overflowX: 'scroll',
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											fontWeight: '500',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
										// '& .MuiDataGrid-cell:focus': {
										// 	outline: 'none',
										// },
										// '& .MuiDataGrid-cell:focus-within': {
										// 	outline: 'none',
										// },
										'& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
											backgroundColor: 'rgba(75, 123, 236, 0.15)',
										},
										'& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover, & .MuiDataGrid-row.Mui-selected.Mui-hovered':
											{
												backgroundColor: 'rgba(75, 123, 236, 0.3)',
											},
										'& .MuiDataGrid-columnHeader': {
											backgroundColor: '#3C4F8F',
											color: '#fff',
										},
										'& .MuiIconButton-root': {
											// color: '#fff',
										},
										'& .MuiDataGrid-columnHeaders': { backgroundColor: '#3C4F8F' },
										'& .activeRow': {
											backgroundColor: 'rgba(75, 207, 250,0.5)',
										},
										'& .MuiDataGrid-cellCheckbox span': {
											padding: '3.5px',
										},
									}}
								/>
							</Box>
						</div>
					</DragWrapper>
				</PageBg>
				<div style={{ display: 'none' }}>
					<DataGridPremium
						page={1}
						apiRef={excelDownloadRef}
						rows={excelDataGridRows}
						getRowId={(row) => row.rowNum}
						pagination
						localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
						components={{
							Toolbar: GridToolbar,
							LoadingOverlay: LinearProgress,
						}}
						componentsProps={{
							toolbar: {
								csvOptions: { disableToolbarButton: true },
								excelOptions: { disableToolbarButton: true },
								printOptions: { disableToolbarButton: true },
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 250 },
							},
						}}
						getDetailPanelHeight={({ row }) => 'auto'}
						density={(env.desnse as GridDensity) || 'compact'}
						getRowHeight={() => 'auto'}
						initialState={{
							columns: {
								columnVisibilityModel: {
									rowNum: true,
									ReceptionDate:
										userInfo.clientID === 2080 ||
										userInfo.clientID === 1792 ||
										userInfo.clientID === 1635
											? true
											: false,
									D90:
										userInfo.clientID === 2080 ||
										userInfo.clientID === 1792 ||
										userInfo.clientID === 1635
											? true
											: false,
									ReceptionDate2:
										userInfo.clientID === 2080 ||
										userInfo.clientID === 1792 ||
										userInfo.clientID === 1635
											? false
											: true,
								},
							},
						}}
						columns={[
							{
								field: 'ReceptionDate',
								headerName: '접수일자',
								width: 100,
							},
							{
								field: 'ReceptionDate2',
								headerName: '접수일자',
								width: 100,
							},
							{
								field: 'PatientName',
								headerName: '수진자',
								width: 100,
							},
							{
								field: 'ChartNo',
								headerName: '챠트번호',
								width: 100,
							},
							{
								field: 'SocialNumber',
								headerName: '생년월일',
								width: 100,
							},
							{
								field: 'Gender',
								headerName: '성별',
								width: 100,
							},
							{
								field: 'DepartName',
								headerName: '진료과',
								type: 'string',
								width: 130,
							},
							{
								field: 'TestCode',
								headerName: '검사코드',
								width: 100,
							},
							{
								field: 'QualityAddCode',
								headerName: '보험코드',
								width: 100,
							},
							{
								field: 'TestName',
								headerName: '검사항목',
								width: 300,
							},
							{
								field: 'ResultData',
								headerName: '검사결과1',
								width: 300,
							},
							{
								field: 'ResultData2',
								headerName: '검사결과2',
								width: 300,
							},
							{
								field: 'PrvResultData',
								headerName: '이전결과',
								width: 300,
							},
							{
								field: 'Decision',
								headerName: '판정',
								width: 100,
							},
							{
								field: 'ResultText',
								headerName: '소견결과',
								width: 300,
							},
							{
								field: 'Remark',
								headerName: '주석',
								width: 100,
							},
							{
								field: 'Reference',
								headerName: '참고치',
								width: 100,
							},
							{
								field: 'Unit',
								headerName: '단위',
								width: 100,
							},
							{
								field: 'SpecimenName',
								headerName: '검체명',
								width: 100,
							},
						]}
						pageSize={10}
						autoHeight={true}
					/>
				</div>
			</LocalizationProvider>
		</>
	)
}

export default ResultV2
